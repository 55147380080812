import React, { useState, useEffect } from 'react'
import Footer from "../components/Footer";
import { readTime } from '../App';
import { Link } from 'react-router-dom';
export default function BlogsPage() {
    const [allBlogs, setBlogs] = useState([]);
    useEffect(() => {
        fetch('http://localhost:3001/api/blogs/allBlogs')
            .then((response) => response.json())
            .then((data) => {
                setBlogs(data);
            })
    }, []);
    var chunks = 2;
    if (allBlogs) {
        var chunksOfAllBlogs = Array.from({ length: Math.ceil(allBlogs.length / chunks) }, (v, i) => allBlogs.slice(i * chunks, i * chunks + chunks));
    }

    return (
        <>
            <section className="maf_home_slide_1 maf_content d-flex flex-column justify-content-center">
                <div className="d-flex flex-column align-items-center">
                    <div className="col-12 col-md-8 col-lg-8 d-flex flex-column justify-content-center align-items-center">
                        <p className="maf_blue_p mb-0 text-start text-center">
                            PORTFOLIO
                        </p>
                        <h1 className="maf_h1 mb-0 text-start text-center">CHECK OUT OUR NEW BLOGS UPDATES</h1>
                        <p className="maf_p mb-0 text-start-md text-center">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                        <button className="maf_btn_play">
                            <span className="">
                                <svg width="84" height="84" className="maf_play_icon" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="42" cy="42" r="30" fill="#004DF9" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M49.5764 39.4141C51.5484 40.5741 51.5484 43.4258 49.5764 44.5857L38.5211 51.0889C36.5212 52.2653 34 50.8233 34 48.5031L34 35.4967C34 33.1765 36.5212 31.7345 38.5211 32.9109L49.5764 39.4141Z" fill="white" />
                                    <circle cx="42" cy="42" r="41.5" stroke="white" strokeOpacity="0.24" />
                                </svg>
                            </span>
                        </button>
                    </div>
                </div>
            </section>
            {chunksOfAllBlogs.map((blog, index) => {
                return (
                    <section key={index} className="maf_home_slide_1 d-none maf_content d-flex flex-column justify-content-center">
                        <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row">
                            {blog.map((item, j) => (
                                <div key={j} className="col-12 col-md-6 d-flex flex-column justify-content-center pe-md-2 pe-lg-3">
                                    <div className="card maf_card_t">
                                        <img src={require(`../assets/img/${item.image}`)} className="card-img-top maf_blog_img_fill" alt={item.short_title} />
                                        <div className="card-body px-0">
                                            <h2 className="card-title maf_h2">{item.short_title}</h2>
                                            <p className="card-text maf_p_s m-0 mb-3">{item.short_desc}</p>
                                            <div className="col d-flex gap_1rm align-items-center">
                                                <Link to={`/blogDetails/${item._id}`}>
                                                    <button className="maf_btn mt-0">
                                                        Read More
                                                    </button>
                                                </Link>
                                                <a href="/" className="maf_a">
                                                    <p className="maf_p_s m-0">{readTime(item.desc)}</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </section>
                );
            })}

            <Footer />
        </>
    )
}
