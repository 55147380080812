import React from 'react'
// import LATETS_WORK1 from '../assets/img/LATETS_WORK1.png'
import LATETS_WORK1 from '../assets/img/work_samples/backpackr.png'
// import LATETS_WORK2 from '../assets/img/LATETS_WORK2.png'
import LATETS_WORK2 from '../assets/img/work_samples/Heads & Tails Jewellery.png'
// import LATETS_WORK3 from '../assets/img/LATETS_WORK3.png'
import LATETS_WORK3 from '../assets/img/work_samples/Stream Art.png'
// import LATETS_WORK4 from '../assets/img/LATETS_WORK4.png'
import LATETS_WORK4 from '../assets/img/work_samples/Mexico Tour Deals.png'
// import LATETS_WORK5 from '../assets/img/LATETS_WORK5.png'
import LATETS_WORK5 from '../assets/img/work_samples/appie.png'
// import LATETS_WORK6 from '../assets/img/LATETS_WORK6.png'
import LATETS_WORK6 from '../assets/img/work_samples/bitcoin.png'
// import LATETS_WORK7 from '../assets/img/LATETS_WORK7.png'
import LATETS_WORK7 from '../assets/img/work_samples/power house.png'
// import LATETS_WORK8 from '../assets/img/LATETS_WORK8.png'
import LATETS_WORK8 from '../assets/img/work_samples/go green.png'
// 2014
export default function Portfolio() {

  return (
    <section className="maf_home_slide_1 d-none maf_content  d-flex flex-column justify-content-center">
        <div className="d-flex flex-column">
            <div className="col-12 d-flex flex-column justify-content-center">
                <p className="maf_blue_p mb-3">
                Portfolio 
                </p>
                <h1 className="maf_h1 mb-0">CHECK OUT OUR LATEST WORK</h1>
            </div>
            <div className="col-12 d-flex flex-column justify-content-center">
                <div className="maf_portfolio_cards mt-2">
                    <div className="maf_card maf_br_12p">
                        <img src={LATETS_WORK1} className="maf_img_portfolio" alt="Latest Work Samples"/>
                    </div>
                    <div className="maf_card maf_br_12p">
                        <img src={LATETS_WORK2} className="maf_img_portfolio" alt="Latest Work Samples"/>
                    </div>
                    <div className="maf_card maf_br_12p">
                        <img src={LATETS_WORK3} className="maf_img_portfolio" alt="Latest Work Samples"/>
                    </div>
                    <div className="maf_card maf_br_12p">
                        <img src={LATETS_WORK4} className="maf_img_portfolio" alt="Latest Work Samples"/>
                    </div>
                    <div className="maf_card maf_br_12p">
                        <img src={LATETS_WORK5} className="maf_img_portfolio" alt="Latest Work Samples"/>
                    </div>
                    <div className="maf_card maf_br_12p">
                        <img src={LATETS_WORK6} className="maf_img_portfolio" alt="Latest Work Samples"/>
                    </div>
                    <div className="maf_card maf_br_12p">
                        <img src={LATETS_WORK7} className="maf_img_portfolio" alt="Latest Work Samples"/>
                    </div>
                    <div className="maf_card maf_br_12p">
                        <img src={LATETS_WORK8} className="maf_img_portfolio" alt="Latest Work Samples"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
