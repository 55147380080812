import React from 'react'
import ServicesIcon from '../assets/img/services.svg'
import TruckIcon from '../assets/img/truck.svg'
import ResearchIcon from '../assets/img/research.svg'
import QualityIcon from '../assets/img/quality_work.svg'
import StarIcon from '../assets/img/star-icon.svg'
import SupportIcon from '../assets/img/support.svg'
import Blog_1_home from '../assets/img/Blog_1_home.png'
import Blog_2_home from '../assets/img/Blog_2_home.png'
import Blog_3_home from '../assets/img/Blog_3_home.png'
import Team_m1 from '../assets/img/Team_m1.png'
import Team_m2 from '../assets/img/Team_mb2.png'
import Team_m3 from '../assets/img/Team_mb3.png'
import Team_m4 from '../assets/img/Team_mb4.png'
import Team_m5 from '../assets/img/Team_mb5.png'
import Footer from "../components/Footer";
export default function ServicesPage() {
    return (
        <>
            <section className="maf_home_slide_1 maf_content  d-flex flex-column justify-content-center">
                <div className="d-flex flex-column align-items-center">
                    <div className="col-12 col-md-8 d-flex flex-column justify-content-center align-items-center">
                        <p className="maf_blue_p mb-0 text-center">
                            OUR SERVICES
                        </p>
                        <h1 className="maf_h1 mb-0 text-center">WE PROVIDE BEST SERVICES IN WORLD</h1>
                        <p className="maf_p mb-0 text-center">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                        <button className="maf_btn_play">
                            <span className="">
                                <svg width="84" height="84" className="maf_play_icon" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="42" cy="42" r="30" fill="#004DF9" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M49.5764 39.4141C51.5484 40.5741 51.5484 43.4258 49.5764 44.5857L38.5211 51.0889C36.5212 52.2653 34 50.8233 34 48.5031L34 35.4967C34 33.1765 36.5212 31.7345 38.5211 32.9109L49.5764 39.4141Z" fill="white" />
                                    <circle cx="42" cy="42" r="41.5" stroke="white" strokeOpacity="0.24" />
                                </svg>
                            </span>
                        </button>
                    </div>
                </div>
            </section>

            <section className="maf_home_slide_1 maf_content  d-flex flex-column justify-content-center">
                <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row">
                    <div className="col-12 col-md-6 mt-4 mt-md-0 d-flex flex-column justify-content-center order-1 order-md-0">
                        <p className="maf_blue_p mb-0">
                            SERVICES
                        </p>
                        <h1 className="maf_h1 mb-0">HOW WE CAN HELP YOU</h1>
                        <p className="maf_p mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.
                            But also the leap into electronic typesetting.
                            Remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently.</p>
                    </div>
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-center order-0 order-md-1">
                        <span className="maf_services_page_icons ps-md-4">
                            <img src={ServicesIcon} className="maf_services_icon" alt="" />
                        </span>
                    </div>
                </div>
            </section>

            <section className="maf_home_slide_1 maf_content  d-flex flex-column justify-content-center">
                <div className="d-flex flex-column flex-sm-column flex-md-column flex-lg-row">
                    <div className="col-12 col-md-12 col-lg-4 d-flex flex-column justify-content-center">
                        <p className="maf_blue_p mb-0">
                            OUR SERVICES
                        </p>
                        <h1 className="maf_h1 mb-0">WHAT YOU CAN EXPECT</h1>
                        <p className="maf_p mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                    </div>
                    <div className="col-12 col-md-12 col-lg-8 mt-4 mt-md-3 mt-lg-0 d-flex flex-column justify-content-center">
                        <div className="maf_services_expect ps-lg-3">
                            <div className="maf_card p-3 d-flex flex-column">
                                <span>
                                    <img className="maf_services_page_icons" src={TruckIcon} alt="Ontime Delivery" />
                                </span>
                                <h5 className="maf_h5 mb-0 mt-2">ON TIME DELIVERY</h5>
                                <p className="maf_p_s mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                            </div>
                            <div className="maf_card p-3 d-flex flex-column">
                                <span>
                                    <img className="maf_services_page_icons" src={ResearchIcon} alt="Research" />
                                </span>
                                <h5 className="maf_h5 mb-0 mt-2">RESEARCH</h5>
                                <p className="maf_p_s mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                            </div>
                            <div className="maf_card p-3 d-flex flex-column">
                                <span>
                                    <img className="maf_services_page_icons" src={QualityIcon} alt="Quality Work" />
                                </span>
                                <h5 className="maf_h5 mb-0 mt-2">QUALITY WORK</h5>
                                <p className="maf_p_s mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                            </div>
                            <div className="maf_card p-3 d-flex flex-column">
                                <span>
                                    <img className="maf_services_page_icons" src={SupportIcon} alt="Ongoing Support" />
                                </span>
                                <h5 className="maf_h5 mb-0 mt-2">ONGOIN SUPPORT</h5>
                                <p className="maf_p_s mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="maf_home_slide_1 maf_content  d-flex flex-column justify-content-center">
                <div className="d-flex flex-column flex-sm-column flex-md-column flex-lg-column">
                    <div className="col-12 d-flex flex-column flex-md-row mb-md-4">
                        <div className="col-12 col-md-3 d-flex">
                            <img src={Team_m1} className="maf_id_img" alt='User img' />
                        </div>
                        <div className="col-12 col-md-9 mt-4 mt-md-0 d-flex flex-column ps-md-4 px-xl-5">
                            <span>
                                <img src={StarIcon} className="maf_services_page_star_icon" alt="HIGH USABILITY AND DEISGN"/>
                            </span>
                            <h3 className="maf_h3 mb-0">HIGH USABILITY AND DEISGN</h3>
                            <p className="maf_p my-0">
                                Lorem Ip  sum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.
                                But also the leap into electronic typesetting.
                                Remaining.
                            </p>
                            <div className="col d-flex flex-column justify-content-end">
                                <p className="maf_p_s maf_c_white m-0">Aamon Hylo</p>
                                <p className="maf_p_xxs m-0 mt-2">Jun 27, 2020 ·</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-4 mt-md-0 d-flex flex-row justify-content-center">
                        <div className="d-flex justify-content-center" id="owl_carousel_services">
                            <div className="item">
                                <a href="/" className="maf_a">
                                    <img src={Team_m1} className="card-img-top" alt="..." />
                                </a>
                            </div>
                            <div className="item">
                                <a href="/" className="maf_a">
                                    <img src={Team_m2} className="card-img-top" alt="..." />
                                </a>
                            </div>
                            <div className="item">
                                <a href="/" className="maf_a">
                                    <img src={Team_m3} className="card-img-top" alt="..." />
                                </a>
                            </div>
                            <div className="item">
                                <a href="/" className="maf_a">
                                    <img src={Team_m4} className="card-img-top" alt="..." />
                                </a>
                            </div>
                            <div className="item">
                                <a href="/" className="maf_a">
                                    <img src={Team_m5} className="card-img-top" alt="..." />
                                </a>
                            </div>
                            <div className="item">
                                <a href="/" className="maf_a">
                                    <img src={Team_m4} className="card-img-top" alt="..." />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="maf_home_slide_1 maf_content   d-flex flex-column justify-content-center">
                <div className="d-flex flex-column">
                    <div className="col-12 d-flex flex-column justify-content-center">
                        <p className="maf_blue_p mb-0">
                            CASE STDIES
                        </p>
                        <h1 className="maf_h1 mb-0">READ CASE STUDIES</h1>
                    </div>
                    <div className="col-12 d-flex mt-4 mt-md-2 flex-column justify-content-center">
                        <div className="col-12 maf_blog_cards">
                            <a href="/" className="maf_a">
                                <div className="card col-12 maf_card maf_br_xt8p">
                                    <img src={Blog_1_home} className="card-img-top " alt="Our top services trending in market this year" />
                                    <div className="card-body d-flex flex-column justify-content-between">
                                        <h4 className="card-title maf_h4">Our top services trending in market this year</h4>
                                        <p className="card-text maf_p_s">UI/UX design</p>
                                    </div>
                                </div>
                            </a>
                            <a href="/" className="maf_a">
                                <div className="card col-12 maf_card maf_br_xt8p">
                                    <img src={Blog_2_home} className="card-img-top " alt="Upcoming services by our company are live now" />
                                    <div className="card-body d-flex flex-column justify-content-between">
                                        <h4 className="card-title maf_h4">Upcoming services by our company are live now</h4>
                                        <p className="card-text maf_p_s">UI/UX design</p>
                                    </div>
                                </div>
                            </a>
                            <a href="/" className="maf_a">
                                <div className="card col-12 maf_card maf_br_xt8p">
                                    <img src={Blog_3_home} className="card-img-top " alt="Guide to be more successfull course is live now" />
                                    <div className="card-body d-flex flex-column justify-content-between">
                                        <h4 className="card-title maf_h4">Guide to be more successfull course is live now</h4>
                                        <p className="card-text maf_p_s">UI/UX design</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
