import React, { useRef } from 'react'
import Slider from 'react-slick'
import StarIcon from '../assets/img/star-icon.svg'
import SaudiArabiaFlag from '../assets/img/saudi_arabia.png'
import USFlag from '../assets/img/US.webp'
import PKFlag from '../assets/img/pk.webp'
import GermanyFlag from '../assets/img/germany.webp'
import ThailandFlag from '../assets/img/thailand.webp'
import SwitzerlandFlag from '../assets/img/switzerland.webp'
import AustraliaFlag from '../assets/img/australia.webp'
import NewZealandFlag from '../assets/img/New-Zealand.png'
import LatviaFlag from '../assets/img/Latvia.webp'
import SloveniaFlag from '../assets/img/Slovenia.png'
import SwedenFlag from '../assets/img/Sweden.png'
import NicaraguaFlag from '../assets/img/Nicaragua.png'
import TurkeyFlag from '../assets/img/Turkey.png'
import UnitedKingdomFlag from '../assets/img/United Kingdom.webp'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
export default function Testimonials() {
  var testimonials = [
    {
      content: 'Thanks for your great job. as always the seller is great, and so fast and has a good communication skills. he fixed issue without needs to explain it too much, I will work with him again on more projects. Recommended :)',
      flag: TurkeyFlag,
      name: 'Mspirit7',
      date: 'Dec 24, 2022',
    }, {
      content: 'We received the final product site calculator as requested. Fiverr continues to provide great temp staff, and Asif did and communicated excellent and delivered a head of time!!! Highly recommended as a website plug in developer.',
      flag: NicaraguaFlag,
      name: 'Pauldaemen',
      date: 'Sep 06, 2022',
    }, {
      content: 'As a "full stack" developer and excellent communicator, he was ideal for our application. We will definitely use Asif in the future as we grow.',
      flag: USFlag,
      name: 'Jstephenwilson',
      date: 'Dec 14, 2022',
    }, {
      content: 'Extremely satisfied. I got excellent code that works perfectly that was very quick and affordable. I had spent a lot of time trying to solve this problem and a lot of hours asking for help here where others had told me this result wasn\'t possible but this seller delivered.',
      flag: UnitedKingdomFlag,
      name: 'Eightyliam',
      date: 'Jul 06, 2022',
    }, {
      content: 'Mr.Faheem is very skilled. He made the plugin exactly as needed and in the most efficient way possible. The plugin is intelligent and it loads the script only in scenarios where it\'s function is required. Definitely I recommend working with Mr.Faheem',
      flag: SaudiArabiaFlag,
      name: 'Anasalwajih',
      date: 'Sep 21, 2022',
    }, {
      content: 'Great service, plugin was exactly what we wanted right from the start. Fast delivery and nice interface. The first delivery had a very minor bug in it which was fixed immediately. Definitely recommend this developer.',
      flag: SloveniaFlag,
      name: 'Mwelschbillig',
      date: 'Mar 27, 2022',
    }, {
      content: 'It was pleasant to work with mian_asif_fahim. He did a great job with a less requirements and pointed all needs out and delivered in time a perfectly working plugin! Would recommand to work with him!',
      flag: SwitzerlandFlag,
      name: 'Studi31',
      date: 'Mar 19, 2022',
    }, {
      content: 'Super quick - understood what I needed and wrote up a custom function for WordPress / WooCommerce that does what I need it to do. Will be back!',
      flag: SloveniaFlag,
      name: 'Yellowstonedm',
      date: 'Jan 22, 2022',
    }, {
      content: 'One of the best experiences I\'ve had on Fiverr since I joined! Very professional, replies fast and writes good code!',
      flag: SwedenFlag,
      name: 'Addegj',
      date: 'Feb 08, 2022',
    }, {
      content: 'Really proffesional service. He delivered exactly what I needed and made immediate repairs on some small details that didnt work in the first delivery. Will definitely work with him again and would definitely recommend him to everyone who need a proffesional custom plugin for wordpress.',
      flag: SloveniaFlag,
      name: 'Markec59',
      date: 'Mar 12, 2022',
    }, {
      content: 'One of the most comfortable sellers I\'ve ever worked with. Understood the job, had an insanely fast delivery and communication was really easy. Will definitely work with him again.',
      flag: SwitzerlandFlag,
      name: 'K1p57a',
      date: 'Dec 02, 2021',
    }, {
      content: 'Mian did an absolutely perfect job. It was my first order on Fiverr and I was not sure how it will go but I am extremely happy with the result. The task was finished in just a few days and exceeded expectations while the price was very fair. There were absolutely no issues and communication was perfectly clear as well. Highly recommended.',
      flag: LatviaFlag,
      name: 'Robliv5',
      date: 'Nov 29, 2021',
    }, {
      content: 'Great, seller asked just the right question to quickly define the brief and then promptly delivered what I needed.',
      flag: NewZealandFlag,
      name: 'New2web',
      date: 'Nov 20, 2021',
    }, {
      content: 'The brightest, kindest, most professional developer I have ever met. His English is spot on, and UNDERSTANDS what your needs are! I will use him from now on for anything dev wise! He’s a genius, and has even fixed issues OTHER people made on plugins, sites, and more. You won’t regret hiring this guy!!!',
      flag: USFlag,
      name: 'Partman4423',
      date: 'Oct 22, 2021',
    }, {
      content: 'Good, talented, and reliable WordPress plugin developer. Understand instructions and communicate well. Will keep on getting him and will recommend him to my business friends and colleagues.',
      flag: USFlag,
      name: 'Prosper2011',
      date: 'Nov 11, 2021',
    }, {
      content: 'The seller was fantastic and produced exactly what I wanted. Actually better. If you need a WordPress plugin this is your gig!',
      flag: USFlag,
      name: 'Mackmansc',
      date: 'Nov 08, 2021',
    }, {
      content: 'Very fast delivery, competent and very good quality result. Detailed explanation of the code and integration process into Wordpress. Absolute recommendation. Thank you very much!',
      flag: GermanyFlag,
      name: 'Logic_analyzer',
      date: 'Oct 22, 2021',
    }, {
      content: 'Fantastic WP Plugin Developer.. my idea was simple but he just knew exactly what to do.. We had to refine it to make it perfect but worked great at the end. Will use again.',
      flag: AustraliaFlag,
      name: 'Harbik',
      date: 'Sep 12, 2021',
    }, {
      content: 'mian_asif_fahim is a truly good egg in this world - knowledgable, fast, considerate and easy to work with. It was a joy working together and I will be utilizing them again in the future.',
      flag: USFlag,
      name: 'Sunsoil',
      date: 'Aug 29, 2021',
    }, {
      content: 'It\'s people like mian_asif_fahim that make Fiverr such a great place to do business. He was got the need, executed quickly and accurately. VERY GOOD WORK!!! He\'s on y favorites now for sure!',
      flag: USFlag,
      name: 'Chrisdarrell',
      date: 'Aug 18, 2021',
    }, {
      content: 'This worker is AMAZING. I\'ve chatted with several workers and mlan_asif_fahim understood very fast what is needed and how to rephrase it and improve it. While the expected time was short, he delivered before. And with best quality. Recommended!!',
      flag: SwitzerlandFlag,
      name: 'Timoboehme',
      date: 'Sep 03, 2021',
    }, {
      content: 'I submitted my requirements for a plugin and the provider adapted the plugin so that all requirements were met. The communication was great and the delivery was on time',
      flag: SwitzerlandFlag,
      name: 'Webkoala',
      date: 'Jun 11, 2021',
    }, {
      content: 'This guy is the real deal, he does what he preaches. I will order again, I could feel he is a real professional and trustworthy in all in its sense. I loved working with him and will do so in the near future. Deliver his work way before the time arrives, fantastic. Thank you very much.',
      flag: PKFlag,
      name: 'Umairkhan_',
      date: 'Dec 18, 2020',
    }, {
      content: 'What can i say, this was excellent work, very professional Truth be known, i asked three so called wordpress experts to resolve this issue. All declined, not on price but on technical ability If you have anything other than a basic issue then I can highly recommend this TRUE EXPERT !!!!!!!',
      flag: ThailandFlag,
      name: 'Chrisjones257',
      date: 'Feb 06, 2021',
    }, {
      content: 'He did amazing work and an excellent job. I don\'t usually leave ratings on Fiverr but this guy deservers one because he saved me a ton of money and time. Will definitely use him again for future work and projects. Great Job!',
      flag: USFlag,
      name: 'Horacelanier',
      date: 'Sep 24, 2020',
    }, {
      content: 'I am BEYOND pleased with the quality of the finished product. Mian is a great communicator, and he definitely knows his stuff. The finished product is exactly what I wanted, and it works perfectly. Although there were a few adjustments to the project along the way, the finished product was delivered before the due date (which has me dancing with joy). I will happily hire him again as the developer for my next project.',
      flag: USFlag,
      name: 'Abundantliving',
      date: 'Jun 09, 2020',
    }, {
      content: 'A true PHP expert I would highly recommend for PHP tasks and any custom WordPress work with themes/plugins. Fast delivery, correct from the first attempt and has excellent communication skills. Will order again.',
      flag: SaudiArabiaFlag,
      name: 'Alihamdan678',
      date: 'Jul 06, 2020',
    }, {
      content: 'mian_asif_fahim is highly one of the top 1% in my eyes! He understood within seconds what I needed from him, made an offer I could not decline and delivered twice as fast as promised. Revision 1 had everything as I wanted it to be - the work is really great. If you ever need someone coding sth in php or else - ask mian_asif_fahim! Mate, I am so glad I found you and will sure hire you a lot more times! Thank you!',
      flag: GermanyFlag,
      name: 'Djembie',
      date: 'May 09, 2020',
    }, {
      content: 'Beyond quick! Thank you! What was planned originally as a 48 hour delivery was instead a same-day delivery! Great experience working with you.',
      flag: PKFlag,
      name: 'Hassanfarooqi12',
      date: 'Sep 10, 2020',
    },
  ];
  var chunks = 6;
  var chunksOfTestimonials = Array.from({ length: Math.ceil(testimonials.length / chunks) }, (v, i) => testimonials.slice(i * chunks, i * chunks + chunks));
  var sliderSettings = {
    dots: false,
    infinite: false,
    adaptiveHeight: false,
    arrows: false,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    centerPadding: '5px',
  };
  const sliderRef = useRef(null);
  const isMobile = window.innerWidth <= 425;

  const handleSlickNext = () => {
    sliderRef.current.slickNext();
  };

  const handleSlickPrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <section className="maf_home_slide_1 d-none maf_testimonials_parent maf_content d-flex flex-column flex-lg-row justify-content-center">
      <div className="col-12 d-flex flex-column flex-md-column flex-lg-row">
        <div className="col col-lg-4 d-flex flex-column justify-content-center">
          <p className="maf_blue_p mb-0">
            Testimonials
          </p>
          <h1 className="maf_h1 mb-0">WHAT OUR CLIENT SAYS?</h1>
          <p className="maf_p mb-0">Read authentic reviews from our satisfied Fiverr clients on our <a href="https://www.fiverr.com/mian_asif_fahim" className='no_text_dec' target='_blank' rel='noreferrer'>dedicated page</a>, showcasing their positive experiences with our services</p>
          <button className="maf_btn">
            Read More
            <span className="maf_arrow_icon">
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.9299 5.92993L20.9999 11.9999L14.9299 18.0699" stroke="#FEFEFE" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M4 12H20.83" stroke="#FEFEFE" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </span>
          </button>
        </div>
        <div className="col col-lg-8 mt-4 mt-md-0 maf_Testimonials_main">

          {isMobile ? (
            <div className="row maf_testimonials">
              <div className="d-flex justify-content-center">
                <button className='maf_clear_btn' onClick={handleSlickPrev}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.07992 15.05L10.5999 8.52999C11.3699 7.75999 12.6299 7.75999 13.3999 8.52999L19.9199 15.05" stroke="#FEFEFE" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
                <Slider {...sliderSettings} ref={sliderRef} className="">
                  {testimonials.map((item, j) => (
                    <div key={j} className='d-flex maf_slider_item_parent'>
                      <div className="col-md-8 px-0 ps-md-2 ps-lg-2 d-flex flex-column gap_0_5rm">
                        <div key={j} className="maf_card d-flex flex-column p-3 mb-3">
                          <img src={StarIcon} className="maf_star_icon mb-1" alt="StarIcon" />
                          <p className="maf_p_xs">
                            {item.content}
                          </p>
                          <div className="d-flex">
                            <div className='maf_testimonials_id'>
                              <img src={item.flag} className="maf_Testimonials_img" alt="name" />
                            </div>
                            <div className="d-flex flex-column ms-2">
                              <h1 className="maf_p_xs fw_600 maf_c_white mb-0">{item.name}</h1>
                              <p className="maf_p_xxs mb-0">{item.date} · 6 min read</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
                <button className='maf_clear_btn maf_next_slide_btn' onClick={handleSlickNext}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.07992 8.95L10.5999 15.47C11.3699 16.24 12.6299 16.24 13.3999 15.47L19.9199 8.95" stroke="#FEFEFE" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className="row mx-0">
                <span className="d-flex justify-content-center">
                  <button className='maf_clear_btn' onClick={handleSlickPrev}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.07992 15.05L10.5999 8.52999C11.3699 7.75999 12.6299 7.75999 13.3999 8.52999L19.9199 15.05" stroke="#FEFEFE" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </button>
                </span>
              </div>
              <div className="row maf_testimonials">
                <Slider {...sliderSettings} ref={sliderRef} className="">
                  {chunksOfTestimonials.map((testimonialChunk, index) => {
                    const chunksOfTestimonialsNew = Array.from({ length: Math.ceil(testimonialChunk.length / 2) }, (v, i) => testimonialChunk.slice(i * 2, i * 2 + 2));
                    return (
                      <div key={index} className='d-flex maf_slider_item_parent'>
                        {chunksOfTestimonialsNew.map((item, j) => (
                          <div key={j} className="col-md-4 px-0 ps-md-2 ps-lg-2 d-flex flex-column gap_0_5rm">
                            {item.map(({ content, flag, name, date }, i) => (
                              <div key={i} className="maf_card d-flex flex-column p-3 mb-3">
                                <img src={StarIcon} className="maf_star_icon mb-1" alt="StarIcon" />
                                <p className="maf_p_xs">
                                  {content}
                                </p>
                                <div className="d-flex">
                                  <div className='maf_testimonials_id'>
                                    <img src={flag} className="maf_Testimonials_img" alt="name" />
                                  </div>
                                  <div className="d-flex flex-column ms-2">
                                    <h1 className="maf_p_xs fw_600 maf_c_white mb-0">{name}</h1>
                                    <p className="maf_p_xxs mb-0">{date} · 6 min read</p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    );
                  })}

                </Slider>
              </div>
              <div className="row mx-0">
                <span className="d-flex justify-content-center">
                  <button className='maf_clear_btn maf_next_slide_btn' onClick={handleSlickNext}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.07992 8.95L10.5999 15.47C11.3699 16.24 12.6299 16.24 13.3999 15.47L19.9199 8.95" stroke="#FEFEFE" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </button>
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </section >
  )
}
