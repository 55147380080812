import React from 'react'
import Rectangle1 from '../assets/img/Rectangle 100.png'
import Rectangle2 from '../assets/img/Rectangle 101.png'
import Rectangle3 from '../assets/img/Rectangle 103.png'
import Rectangle4 from '../assets/img/Rectangle 104.png'
import Rectangle5 from '../assets/img/Rectangle 105.png'
import Rectangle6 from '../assets/img/Rectangle 106.png'
import App1 from '../assets/img/App1.png'
import App2 from '../assets/img/App2.png'
import App3 from '../assets/img/App3.png'
import App4 from '../assets/img/App4.png'
import App5 from '../assets/img/App5.png'
import App6 from '../assets/img/App6.png'
import WEBSITES_1 from '../assets/img/WEBSITES_1.png'
import WEBSITES_2 from '../assets/img/WEBSITES_2.png'
import WEBSITES_3 from '../assets/img/WEBSITES_3.png'
import WEBSITES_4 from '../assets/img/WEBSITES_4.png'
import WEBSITES_5 from '../assets/img/WEBSITES_5.png'
import WEBSITES_6 from '../assets/img/WEBSITES_6.png'
import DISKIRZ from '../assets/img/DISKIRZ.png'
import QUSTIONIcon from '../assets/img/QUSTION.png'
import Footer from "../components/Footer";
export default function PortfolioPage() {
    return (
        <>
            <section className="maf_home_slide_1 maf_content d-flex flex-column justify-content-center">
                <div className="d-flex flex-column align-items-center">
                    <div className="col-12 col-md-8 col-lg-8 d-flex flex-column justify-content-center align-items-center">
                        <p className="maf_blue_p mb-0 text-start text-center">
                            PORTFOLIO
                        </p>
                        <h1 className="maf_h1 mb-0 text-start text-center">CHECK OUT OUR  BEST OF WORKS EVER</h1>
                        <p className="maf_p mb-0 text-start-md text-center">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                        <button className="maf_btn_play">
                            <span className="">
                                <svg width="84" height="84" className="maf_play_icon" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="42" cy="42" r="30" fill="#004DF9" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M49.5764 39.4141C51.5484 40.5741 51.5484 43.4258 49.5764 44.5857L38.5211 51.0889C36.5212 52.2653 34 50.8233 34 48.5031L34 35.4967C34 33.1765 36.5212 31.7345 38.5211 32.9109L49.5764 39.4141Z" fill="white" />
                                    <circle cx="42" cy="42" r="41.5" stroke="white" strokeOpacity="0.24" />
                                </svg>
                            </span>
                        </button>
                    </div>
                </div>
            </section>

            <section className="maf_home_slide_1 maf_content d-flex flex-column justify-content-center">
                <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row">
                    <div className="col-12 col-md-5 d-flex flex-column justify-content-center">
                        <p className="maf_blue_p mb-0">
                            USE CASE
                        </p>
                        <h1 className="maf_h1 mb-0">CHECK OUT OUR DEVELOPMENT WORK</h1>
                        <p className="maf_p mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                    </div>
                    <div className="col-12 col-md-7 mt-4 mt-md-0 d-flex flex-column justify-content-center">
                        <div className="maf_work_cards ps-md-3 ps-lg-4">
                            <a href="/" className="maf_a maf_card maf_br_12p">
                                <img src={Rectangle1} className="maf_work_img" alt="..." />
                            </a>
                            <a href="/" className="maf_a maf_card maf_br_12p">
                                <img src={Rectangle2} className="maf_work_img" alt="..." />
                            </a>
                            <a href="/" className="maf_a maf_card maf_br_12p">
                                <img src={Rectangle3} className="maf_work_img" alt="..." />
                            </a>
                            <a href="/" className="maf_a maf_card maf_br_12p">
                                <img src={Rectangle4} className="maf_work_img" alt="..." />
                            </a>
                            <a href="/" className="maf_a maf_card maf_br_12p">
                                <img src={Rectangle5} className="maf_work_img" alt="..." />
                            </a>
                            <a href="/" className="maf_a maf_card maf_br_12p">
                                <img src={Rectangle6} className="maf_work_img" alt="..." />
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="maf_home_slide_1 maf_content d-flex flex-column justify-content-center">
                <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row">
                    <div className="col-12 col-md-7 mt-4 mt-md-0 d-flex flex-column justify-content-center order-1 order-md-0">
                        <div className="maf_work_cards pe-md-3 pe-lg-4">
                            <a href="/" className="maf_a maf_card maf_br_12p">
                                <img src={App1} className="maf_work_img" alt="..." />
                            </a>
                            <a href="/" className="maf_a maf_card maf_br_12p">
                                <img src={App2} className="maf_work_img" alt="..." />
                            </a>
                            <a href="/" className="maf_a maf_card maf_br_12p">
                                <img src={App3} className="maf_work_img" alt="..." />
                            </a>
                            <a href="/" className="maf_a maf_card maf_br_12p">
                                <img src={App4} className="maf_work_img" alt="..." />
                            </a>
                            <a href="/" className="maf_a maf_card maf_br_12p">
                                <img src={App5} className="maf_work_img" alt="..." />
                            </a>
                            <a href="/" className="maf_a maf_card maf_br_12p">
                                <img src={App6} className="maf_work_img" alt="..." />
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-md-5 d-flex flex-column justify-content-center order-0 order-md-1">
                        <p className="maf_blue_p mb-0">
                            USE CASE
                        </p>
                        <h1 className="maf_h1 mb-0">CHECK OUT OUR BEST OF APPS</h1>
                        <p className="maf_p mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                    </div>
                </div>
            </section>

            <section className="maf_home_slide_1 maf_content d-flex flex-column justify-content-center">
                <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row">
                    <div className="col-12 col-md-5 d-flex flex-column justify-content-center">
                        <p className="maf_blue_p mb-0">
                            USE CASE
                        </p>
                        <h1 className="maf_h1 mb-0">CHECK OUT OUR BEST OF WEBSITES</h1>
                        <p className="maf_p mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                    </div>
                    <div className="col-12 col-md-7 d-flex flex-column justify-content-center">
                        <div className="maf_work_cards ps-md-3 ps-lg-4">
                            <a href="/" className="maf_a maf_card maf_br_12p">
                                <img src={WEBSITES_1} className="maf_work_img" alt="..." />
                            </a>
                            <a href="/" className="maf_a maf_card maf_br_12p">
                                <img src={WEBSITES_2} className="maf_work_img" alt="..." />
                            </a>
                            <a href="/" className="maf_a maf_card maf_br_12p">
                                <img src={WEBSITES_3} className="maf_work_img" alt="..." />
                            </a>
                            <a href="/" className="maf_a maf_card maf_br_12p">
                                <img src={WEBSITES_4} className="maf_work_img" alt="..." />
                            </a>
                            <a href="/" className="maf_a maf_card maf_br_12p">
                                <img src={WEBSITES_5}className="maf_work_img" alt="..." />
                            </a>
                            <a href="/" className="maf_a maf_card maf_br_12p">
                                <img src={WEBSITES_6} className="maf_work_img" alt="..." />
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="maf_home_slide_1 maf_content d-flex flex-column justify-content-center">
                <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row">
                    <div className="col-12 col-md-6 mt-4 mt-md-0 d-flex flex-column justify-content-center order-1 order-md-0">
                        <p className="maf_blue_p mb-0">
                            USE CASE
                        </p>
                        <h1 className="maf_h1 mb-0">OUR DISKIRZ PROJECT</h1>
                        <p className="maf_p my-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sed urna tempus tellus ullamcorper tincidunt. Ut tincidunt dolor varius iaculis rutrum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nunc luctus urna accumsan scelerisque condimentum. Sed nec lobortis mi. Pellentesque quis arcu pharetra, malesuada velit.</p>
                        <div className="col-12 d-flex flex-row">
                            <div className="col-12 d-flex flex-row mt-3">
                                <div className="col-5 col-xl-4 d-flex flex-column">
                                    <p className="maf_p maf_c_white fw_600 mb-0">Label</p>
                                    <p className="maf_p maf_c_white fw_600 mb-0">Website</p>
                                    <p className="maf_p maf_c_white fw_600 mb-0">Release Date</p>
                                    <p className="maf_p maf_c_white fw_600 mb-0">Genres</p>
                                    <p className="maf_p maf_c_white fw_600 mb-0">Artists</p>
                                    <p className="maf_p maf_c_white fw_600 mb-0">People</p>
                                </div>
                                <div className="col-7 col-xl-8 d-flex flex-column">
                                    <p className="maf_p mb-0">B.02</p>
                                    <p className="maf_p mb-0">qodeinteractive.com</p>
                                    <p className="maf_p mb-0">October 29, 2021</p>
                                    <p className="maf_p mb-0">Tech House</p>
                                    <p className="maf_p mb-0">Mattea Dega</p>
                                    <p className="maf_p mb-0">Ibeyi, Mosberg</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-center order-0 order-md-1">
                        <span className="ps-md-4">
                            <img src={DISKIRZ} className="maf_diskirz_img" alt="..." />
                        </span>
                    </div>
                </div>
            </section>

            <section className="maf_home_slide_1 maf_content d-flex flex-column justify-content-center">
                <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row">
                    <div className="col-12 col-md-6 mt-4 mt-md-0 d-flex flex-column justify-content-center order-1 order-md-0">
                        <p className="maf_blue_p mb-0">
                            USE CASE
                        </p>
                        <h1 className="maf_h1 mb-0">HAVE ANY QUSTION?</h1>
                        <div className="col-12 d-flex flex-row">
                            <div className="col-12 d-flex flex-column mt-3 maf_gap_1rm">
                                <a href="/" className="maf_a d-flex flex-column">
                                    <div className="col-12 d-flex justify-content-between mb-1">
                                        <p className="maf_p_s my-0">Lorem ipsum dolor sit amet.</p>
                                        <span>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19.92 8.94995L13.4 15.47C12.63 16.24 11.37 16.24 10.6 15.47L4.08002 8.94995" stroke="#878787" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="maf_divider_h">
                                        <span style={{ opacity: 0 }}>.</span>
                                    </div>
                                </a>
                                <a href="/" className="maf_a d-flex flex-column">
                                    <div className="col-12 d-flex justify-content-between mb-1">
                                        <p className="maf_p_s my-0">Lorem ipsum dolor sit amet.</p>
                                        <span>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19.92 8.94995L13.4 15.47C12.63 16.24 11.37 16.24 10.6 15.47L4.08002 8.94995" stroke="#878787" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="maf_divider_h">
                                        <span style={{ opacity: 0 }}>.</span>
                                    </div>
                                </a>
                                <a href="/" className="maf_a d-flex flex-column">
                                    <div className="col-12 d-flex justify-content-between mb-1">
                                        <p className="maf_p_s my-0">Lorem ipsum dolor sit amet.</p>
                                        <span>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19.92 8.94995L13.4 15.47C12.63 16.24 11.37 16.24 10.6 15.47L4.08002 8.94995" stroke="#878787" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="maf_divider_h">
                                        <span style={{ opacity: 0 }}>.</span>
                                    </div>
                                </a>
                                <a href="/" className="maf_a d-flex flex-column">
                                    <div className="col-12 d-flex justify-content-between mb-1">
                                        <p className="maf_p_s my-0">Lorem ipsum dolor sit amet.</p>
                                        <span>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19.92 8.94995L13.4 15.47C12.63 16.24 11.37 16.24 10.6 15.47L4.08002 8.94995" stroke="#878787" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="maf_divider_h">
                                        <span style={{ opacity: 0 }}>.</span>
                                    </div>
                                </a>
                                <a href="/" className="maf_a d-flex flex-column">
                                    <div className="col-12 d-flex justify-content-between mb-1">
                                        <p className="maf_p_s my-0">Lorem ipsum dolor sit amet.</p>
                                        <span>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19.92 8.94995L13.4 15.47C12.63 16.24 11.37 16.24 10.6 15.47L4.08002 8.94995" stroke="#878787" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="maf_divider_h">
                                        <span style={{ opacity: 0 }}>.</span>
                                    </div>
                                </a>
                                <a href="/" className="maf_a d-flex flex-column">
                                    <div className="col-12 d-flex justify-content-between mb-1">
                                        <p className="maf_p_s my-0">Lorem ipsum dolor sit amet.</p>
                                        <span>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19.92 8.94995L13.4 15.47C12.63 16.24 11.37 16.24 10.6 15.47L4.08002 8.94995" stroke="#878787" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="maf_divider_h">
                                        <span style={{ opacity: 0 }}>.</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-center order-0 order-md-1">
                        <span className="ps-md-4">
                            <img src={QUSTIONIcon} className="maf_diskirz_img" alt="..." />
                        </span>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
