import * as React from "react";
import { useEffect, useRef } from "react";
import { motion, useCycle } from "framer-motion";
import { Link } from 'react-router-dom';


export const useDimensions = ref => {
    const dimensions = useRef({ width: 0, height: 0 });

    useEffect(() => {
        dimensions.current.width = ref.current.offsetWidth;
        dimensions.current.height = ref.current.offsetHeight;
    });

    return dimensions.current;
};
export default function MaafMenu(props) {
    const sidebar = {
        open: (height = 1000) => ({
            clipPath: `circle(${height * 2 + 200}px at calc(100% - 40px) 40px)`,
            transition: {
                type: "spring",
                stiffness: 200,
            }
        }),
        closed: {
            clipPath: "circle(30px at calc(100% - 40px) 40px)",
            transition: {
                delay: 0.2,
                type: "spring",
                stiffness: 100,
                damping: 40
            }
        }
    };

    const itemIds = [0, 1, 2, 3, 4];
    const itemName = ['Home', 'About Us', 'Services', 'Portfolio', 'Contact Us', 'Blogs'];
    const itemLinks = ['/', '/about-us', '/services', '/portfolio', '/contact-us', '/blogs',];
    const [isOpen, toggleOpen] = useCycle(false, true);
    const containerRef = useRef(null);
    const { height } = useDimensions(containerRef);
    const variants = {
        open: {
            y: 0,
            opacity: 1,
            transition: {
                y: { stiffness: 1000, velocity: -100 }
            }
        },
        closed: {
            y: 50,
            opacity: 0,
            transition: {
                y: { stiffness: 1000 }
            }
        }
    };
    const navVariants = {
        open: {
            transition: { staggerChildren: 0.07, delayChildren: 0.2 }
        },
        closed: {
            transition: { staggerChildren: 0.05, staggerDirection: -1 }
        }
    };
    const toggleClose = () =>{
        if(isOpen === true){
            toggleOpen(false);
        }
    }
    return (
        <>
            <header className="maf_header container-fluid p-0">
                <div className="d-flex justify-content-between">
                    <Link to='/' onClick={() => toggleClose()}><h3 className="maf_logo_header fw_800 mb-0">MAFF STUDIO</h3></Link>
                </div>
            </header>
            <motion.nav className="maf_nav_parent d-flex flex-column justify-content-center "
                initial={false}
                animate={isOpen ? "open" : "closed"}
                custom={height}
                ref={containerRef}
            >
                <motion.div className="maf_menu_background " variants={sidebar} />
                <motion.ul variants={navVariants} className="maf_menu_ul_parent d-flex flex-column justify-content-center ">
                    {itemIds.map(i => (
                        <motion.li
                            variants={variants}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.95 }} key={i}
                        >
                            <Link to={itemLinks[i]} className="maf_menu_pages my-2" onClick={() => toggleOpen()} >
                                <h2 className="maf_h2 maf_menu_h mb-0" >{itemName[i]}</h2>
                                <div className="maf_menu_pages_bar">
                                    <span style={{ opacity: 0 }}>.</span>
                                </div>
                            </Link>
                        </motion.li>
                    ))}
                </motion.ul>
                <div className="maf_header_buttons d-flex align-items-center" onClick={() => toggleOpen()}>
                    <button onClick={() => toggleOpen()} className="maf_menu_toggle_button">
                        <div className='maf_wrap_div'>
                            <label data-bs-target="#maf_menu_sheet" className={`maf_menu_icon_label ${isOpen ? 'active' : 'inactive'}`} onClick={() => toggleOpen()}>
                                <span className='maf_menu_icon_label_span'></span>
                                <span className='maf_menu_icon_label_span'></span>
                                <span className='maf_menu_icon_label_span'></span>
                            </label>
                        </div>
                    </button>
                </div>
            </motion.nav>
        </>
    );
};
