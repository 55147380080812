import React from 'react'

export default function Services() {
  return (
    <section className="maf_home_slide_1 d-none maf_content  d-flex flex-column justify-content-center">
      <div className="d-flex flex-column">
        <div className="col-12 d-flex flex-column justify-content-center">
          <p className="maf_blue_p mb-3">
            Our services
          </p>
          <h1 className="maf_h1 mb-0">SERVICES WE <br /> PROVIDE TO YOU</h1>
        </div>
        <div className="col-12 d-flex flex-column justify-content-center">
          <div className="maf_services_cards mt-3 mt-lg-4">
            <div className="maf_card p-3 d-flex flex-column">
              <h5 className="maf_h5 my-1">
                <svg className="maf_our_services_icon me-1" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.7469 29.3419L29.3453 18.7572L30.3311 19.7216C31.4337 20.8319 31.9224 21.8821 31.9224 22.874C31.9224 23.8665 31.4331 24.9173 30.3291 26.0284C30.3289 26.0285 30.3288 26.0287 30.3286 26.0288L26.0131 30.3444C24.936 31.4215 23.8746 31.9042 22.8812 31.9042C21.8878 31.9042 20.8264 31.4215 19.7494 30.3444L18.7469 29.3419Z" fill="#004DF9" stroke="#004DF9" />
                  <path d="M8.95735 4.62712L8.95795 4.62653C10.0438 3.54069 11.098 3.05579 12.0971 3.05579C13.0959 3.05579 14.1498 3.54041 15.2353 4.62559C15.2356 4.6259 15.2359 4.62621 15.2363 4.62653L16.2117 5.61271L5.61481 16.2096L4.62783 15.2226C4.62765 15.2225 4.62748 15.2223 4.62731 15.2221C3.5491 14.1368 3.06689 13.0825 3.06689 12.0823C3.06689 11.0823 3.549 10.0281 4.62698 8.94292C4.62726 8.94263 4.62754 8.94235 4.62783 8.94206L8.95735 4.62712Z" fill="#004DF9" stroke="#004DF9" />
                  <path d="M31.3442 5.55471L31.3441 5.55469L31.342 5.55994C29.5602 10.0078 25.5899 15.7829 21.6759 19.8045C20.8151 16.5839 18.2534 14.0693 15.0067 13.2652C19.0436 9.33796 24.8487 5.32286 29.3046 3.52837C29.9552 3.28357 30.5934 3.47567 30.986 3.86819C31.4189 4.30108 31.6058 4.92247 31.3442 5.55471Z" fill="#004DF9" stroke="#004DF9" />
                  <path d="M16.9657 23.8968C16.6425 22.4907 15.9124 21.1992 14.8346 20.1214C13.7375 19.0243 12.4054 18.293 10.941 17.9722L12.525 15.973L12.525 15.973L12.5278 15.9694C12.671 15.7853 12.8229 15.6074 12.9875 15.4201L13.7137 15.5162C16.6318 15.9242 18.9804 18.2204 19.4414 21.1221L19.4414 21.1222L19.442 21.1259L19.5558 21.8086C19.3378 21.9932 19.1229 22.1732 18.9086 22.3447L16.9657 23.8968ZM16.9657 23.8968L18.9083 22.3449L16.9657 23.8968Z" fill="#004DF9" stroke="#004DF9" />
                  <path d="M13.0799 29.6275L13.0799 29.6275L13.0748 29.6326C12.2672 30.4534 11.1777 31.016 9.8303 31.1782L6.2598 31.5717L6.25894 31.5718C4.62532 31.7547 3.21544 30.3489 3.39901 28.6968L3.39908 28.6962L3.79283 25.1087L3.79284 25.1086C4.11262 22.1906 6.54879 20.314 9.15404 20.2603L9.16203 20.2601L9.17001 20.2597C9.4114 20.247 9.68792 20.2592 9.96171 20.2863C11.0864 20.4334 12.1793 20.9503 13.1082 21.8652C14.0071 22.7647 14.5086 23.8133 14.6554 24.9079L14.6578 24.9317C14.6863 25.2165 14.7104 25.4576 14.7104 25.6958C14.7104 27.1705 14.1484 28.5723 13.0799 29.6275Z" fill="#004DF9" stroke="#004DF9" />
                </svg>
                GRAPHIC DESIGN</h5>
              <p className="maf_p_s mb-0">We offer professional graphic design services for branding, websites, apps and print materials. Elevating your visual identity and delivering stunning designs that communicate your message effectively.</p>
            </div>
            <div className="maf_card p-3 d-flex flex-column">
              <h5 className="maf_h5 my-1">
                <svg className="maf_our_services_icon me-1" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M27.3693 14.2224L27.5774 14.4304L27.8602 14.3496C28.0403 14.2981 28.2096 14.2667 28.3646 14.2667H29.9687C30.9574 14.2667 31.7616 15.0611 31.7875 16.0624V17.6605C31.7875 18.6531 30.9759 19.4646 29.9833 19.4646H28.3791C27.3865 19.4646 26.575 18.6531 26.575 17.6605V16.0563C26.575 15.9014 26.6064 15.732 26.6578 15.552L26.7386 15.2691L26.5306 15.0611L19.2535 7.784L18.9469 7.47737L18.6 7.73756C18.2716 7.98383 17.8797 8.11881 17.5 8.11881C17.1091 8.11881 16.7227 7.9917 16.4215 7.75746L16.0731 7.48648L15.761 7.79859L8.48391 15.0757L8.27588 15.2837L8.35671 15.5666C8.40814 15.7466 8.43955 15.916 8.43955 16.0709V17.6751C8.43955 18.6677 7.62799 19.4792 6.63538 19.4792H5.03122C4.03861 19.4792 3.22705 18.6677 3.22705 17.6751V16.0709C3.22705 15.0783 4.03861 14.2667 5.03122 14.2667H6.63538C6.79031 14.2667 6.95968 14.2981 7.13969 14.3496L7.42257 14.4304L7.6306 14.2224L14.9077 6.94528L15.2191 6.63387L14.9495 6.28564C14.3985 5.57396 14.4498 4.55938 15.0827 3.92653L16.2186 2.7906C16.901 2.12127 18.1021 2.12595 18.7652 2.78903L19.9027 3.92653L19.9027 3.92654L19.9058 3.92964C20.5497 4.56227 20.601 5.57451 20.0504 6.28564L19.7808 6.63387L20.0922 6.94528L27.3693 14.2224Z" fill="#004DF9" stroke="#004DF9" />
                  <path d="M12.3042 23.7608L12.2974 23.7548L12.2903 23.749C11.6297 23.2066 11.416 22.817 11.3905 22.4954C11.3642 22.1637 11.5229 21.7393 12.0307 21.0902C12.0309 21.09 12.0311 21.0897 12.0314 21.0894L16.2436 15.7549C16.485 15.4535 16.7445 15.2692 17.0193 15.1734C17.3053 15.0787 17.6151 15.0765 17.9221 15.1734C18.1824 15.2689 18.4439 15.4539 18.7022 15.7598L22.9119 21.0911L22.9122 21.0915C23.4184 21.7313 23.5648 22.1391 23.5355 22.4624C23.506 22.7879 23.2845 23.1842 22.6368 23.7616C22.6366 23.7618 22.6364 23.762 22.6362 23.7622L20.3879 25.75H14.554L12.3042 23.7608Z" fill="#004DF9" stroke="#004DF9" />
                  <path d="M14.0067 29.9327L14.0069 29.9318L14.459 27.9047L14.459 27.9047L14.4599 27.9007C14.4955 27.7344 14.6468 27.6105 14.8314 27.6105H20.1543C20.3242 27.6105 20.4786 27.7221 20.5283 27.9121L20.9788 29.9318L20.9788 29.9318L20.9801 29.9375C21.0884 30.3979 20.9669 30.7923 20.7022 31.0786C20.4315 31.3715 19.9783 31.5834 19.3814 31.5834H15.6043C14.5064 31.5834 13.8304 30.7161 14.0067 29.9327Z" fill="#004DF9" stroke="#004DF9" />
                </svg>

                WEBSITE DESIGN</h5>
              <p className="maf_p_s mb-0">Professional website design services by experienced software house team. Modern, responsive designs tailored to your business needs. Customizable, user-friendly interfaces and latest web technologies used.</p>
            </div>
            <div className="maf_card p-3 d-flex flex-column">
              <h5 className="maf_h5 my-1">
                <svg className="maf_our_services_icon  me-1" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.83341 20.9166H9.72717C10.2531 20.9166 10.6855 21.349 10.6855 21.875V30.625C10.6855 31.1509 10.2531 31.5833 9.72717 31.5833H4.37508C3.84914 31.5833 3.41675 31.1509 3.41675 30.625V23.3333C3.41675 22.0053 4.50539 20.9166 5.83341 20.9166Z" fill="#004DF9" stroke="#004DF9" />
                  <path d="M15.5458 15.0834H19.4396C20.7805 15.0834 21.8562 16.1704 21.8562 17.5V30.625C21.8562 31.151 21.4238 31.5834 20.8979 31.5834H14.0875C13.5615 31.5834 13.1292 31.151 13.1292 30.625V17.5C13.1292 16.172 14.2178 15.0834 15.5458 15.0834Z" fill="#004DF9" stroke="#004DF9" />
                  <path d="M25.273 25.2916H29.1668C30.4948 25.2916 31.5834 26.3803 31.5834 27.7083V30.625C31.5834 31.1509 31.1511 31.5833 30.6251 31.5833H25.273C24.7471 31.5833 24.3147 31.1509 24.3147 30.625V26.25C24.3147 25.724 24.7471 25.2916 25.273 25.2916Z" fill="#004DF9" stroke="#004DF9" />
                  <path d="M21.8895 7.07291C22.3416 6.62082 22.5166 6.08124 22.3708 5.61457C22.2249 5.14791 21.7728 4.81249 21.1312 4.71041L19.7312 4.47707C19.6728 4.47707 19.5416 4.37499 19.5124 4.31666L18.7395 2.77083C18.1562 1.58958 16.8291 1.58958 16.2458 2.77083L15.4728 4.31666C15.4583 4.37499 15.327 4.47707 15.2687 4.47707L13.8687 4.71041C13.227 4.81249 12.7895 5.14791 12.6291 5.61457C12.4833 6.08124 12.6583 6.62082 13.1103 7.07291L14.1895 8.16666C14.2478 8.21041 14.2916 8.38541 14.277 8.44374L13.9708 9.78541C13.7374 10.7917 14.1166 11.2437 14.3645 11.4187C14.6124 11.5937 15.152 11.8271 16.0416 11.3021L17.3541 10.5292C17.4124 10.4854 17.602 10.4854 17.6603 10.5292L18.9583 11.3021C19.3666 11.55 19.702 11.6229 19.9645 11.6229C20.2708 11.6229 20.4895 11.5062 20.6208 11.4187C20.8687 11.2437 21.2478 10.7917 21.0145 9.78541L20.7083 8.44374C20.6937 8.37082 20.7374 8.21041 20.7958 8.16666L21.8895 7.07291Z" fill="#004DF9" />
                </svg>

                SEO SERVICE</h5>
              <p className="maf_p_s mb-0">Expert SEO services by our experienced team. Optimize your website for search engines, increase visibility and drive more traffic. On-page, off-page optimization, keyword research and analytics to improve your website ranking.</p>
            </div>
            <div className="maf_card p-3 d-flex flex-column">
              <h5 className="maf_h5 my-1">
                <svg className="maf_our_services_icon  me-1" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.4592 24.4531L3.45864 24.4444L3.45777 24.4357C3.43025 24.1605 3.4165 23.8854 3.4165 23.6105V16.0417C3.4165 15.5158 3.8489 15.0834 4.37484 15.0834H30.6248C31.1508 15.0834 31.5832 15.5158 31.5832 16.0417V23.6105C31.5832 23.8854 31.5694 24.1605 31.5419 24.4357L31.541 24.4444L31.5405 24.4531C31.4009 26.6019 30.6729 28.2949 29.4838 29.484C28.2947 30.6731 26.6017 31.4011 24.4529 31.5407L24.4442 31.5412L24.4355 31.5421C24.1603 31.5696 23.8852 31.5834 23.6103 31.5834H11.3894C11.1145 31.5834 10.8394 31.5696 10.5642 31.5421L10.5555 31.5412L10.5468 31.5407C8.39799 31.4011 6.70497 30.6731 5.51589 29.484C4.32681 28.2949 3.59874 26.6019 3.4592 24.4531ZM25.862 25.0572L25.8638 25.0545C26.5591 24.0115 26.5591 22.6552 25.8638 21.6123L25.862 21.6096C25.1164 20.5068 24.0899 19.5873 22.8869 18.9929C22.1012 18.5921 21.1418 18.9093 20.7556 19.6997C20.3604 20.4932 20.6687 21.4534 21.4638 21.8421C22.1509 22.1861 22.7398 22.7034 23.1695 23.3334C22.7398 23.9634 22.1509 24.4806 21.4638 24.8246C20.6679 25.2137 20.3598 26.1754 20.7568 26.9695L21.204 26.7459L20.7568 26.9695C21.0351 27.5261 21.5925 27.8438 22.1811 27.8438C22.4329 27.8438 22.6649 27.7752 22.8692 27.6823L22.8765 27.679L22.8836 27.6755C24.0881 27.0811 25.1157 26.161 25.862 25.0572ZM14.5491 26.9695C14.9467 26.1743 14.6368 25.2128 13.8274 24.8245C13.1562 24.4814 12.5672 23.965 12.1231 23.3334C12.5672 22.7018 13.1562 22.1853 13.8274 21.8422C14.636 21.4543 14.9461 20.4944 14.5503 19.6997C14.1643 18.9096 13.2057 18.5924 12.4202 18.9923C11.2022 19.5866 10.174 20.5069 9.44169 21.613C8.74702 22.6556 8.747 24.0111 9.44163 25.0537C10.1744 26.1604 11.2034 27.0811 12.4223 27.6755C12.6321 27.78 12.8824 27.8438 13.1248 27.8438C13.695 27.8438 14.2692 27.5293 14.5491 26.9695Z" fill="#004DF9" stroke="#004DF9" />
                  <path d="M30.6248 12.625H30.6246L4.37484 12.6395C4.37476 12.6395 4.37468 12.6395 4.37461 12.6395C3.84877 12.6394 3.4165 12.2071 3.4165 11.6812V11.3895C3.4165 11.1146 3.43025 10.8395 3.45777 10.5643L3.45864 10.5556L3.4592 10.5469C3.59874 8.39811 4.32681 6.70509 5.51589 5.51601C6.70497 4.32693 8.39799 3.59886 10.5468 3.45933L10.5555 3.45876L10.5642 3.45789C10.8394 3.43037 11.1145 3.41663 11.3894 3.41663H23.6103C23.8852 3.41663 24.1603 3.43037 24.4355 3.45789L24.4442 3.45876L24.4529 3.45933C26.6017 3.59886 28.2947 4.32693 29.4838 5.51601C30.6729 6.70509 31.4009 8.39811 31.5405 10.5469L31.541 10.5556L31.5419 10.5643C31.5694 10.8395 31.5832 11.1146 31.5832 11.3895V11.6666C31.5832 12.1926 31.1508 12.625 30.6248 12.625Z" fill="#004DF9" stroke="#004DF9" />
                </svg>

                CODING</h5>
              <p className="maf_p_s mb-0">Expert coding services, specializing in various languages and technologies such as Python, Ionic, ReactJS, AngularJS, JavaScript, PHP, WordPress and more to meet any project needs. On-time delivery and quality assurance guaranteed.</p>
            </div>
            <div className="maf_card p-3 d-flex flex-column">
              <h5 className="maf_h5 my-1">
                <svg className="maf_our_services_icon me-1" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.4241 14.7218L20.4252 14.719C20.7631 13.9165 20.4043 12.9847 19.5999 12.6248C18.7851 12.2603 17.8469 12.648 17.5064 13.4543C17.5061 13.4549 17.5059 13.4554 17.5057 13.4559L14.5915 20.2606C14.5913 20.2611 14.5911 20.2616 14.5908 20.2621C14.2339 21.0832 14.6116 22.0197 15.42 22.363C15.6181 22.4503 15.832 22.4916 16.0415 22.4916C16.653 22.4916 17.2491 22.1348 17.5073 21.5323L17.5074 21.5322L20.4241 14.7218ZM3.4165 17.5C3.4165 9.7261 9.72598 3.41663 17.4998 3.41663C25.2737 3.41663 31.5832 9.7261 31.5832 17.5C31.5832 25.2738 25.2737 31.5833 17.4998 31.5833C9.72598 31.5833 3.4165 25.2738 3.4165 17.5ZM12.793 21.5431C13.4112 20.9249 13.4112 19.9083 12.793 19.2902L11.0028 17.5L12.793 15.7098C13.4112 15.0916 13.4112 14.075 12.793 13.4568C12.1748 12.8386 11.1582 12.8386 10.54 13.4568L7.62337 16.3735C7.00519 16.9917 7.00519 18.0083 7.62337 18.6264L10.54 21.5431C10.8575 21.8606 11.264 22.0104 11.6665 22.0104C12.069 22.0104 12.4755 21.8606 12.793 21.5431ZM24.4596 21.5431L27.3763 18.6264C27.9945 18.0083 27.9945 16.9917 27.3763 16.3735L24.4596 13.4568C23.8415 12.8386 22.8249 12.8386 22.2067 13.4568C21.5885 14.075 21.5885 15.0916 22.2067 15.7098L23.9969 17.5L22.2067 19.2902C21.5885 19.9083 21.5885 20.9249 22.2067 21.5431C22.5242 21.8606 22.9306 22.0104 23.3332 22.0104C23.7357 22.0104 24.1422 21.8606 24.4596 21.5431Z" fill="#004DF9" stroke="#004DF9" />
                </svg>

                DEVELOPMENT</h5>
              <p className="maf_p_s mb-0">Customized solutions for all types of businesses, from small startups to large enterprises. We provide end-to-end solutions, from website design to development, testing and deployment.</p>
            </div>
            <div className="maf_card p-3 d-flex flex-column">
              <h5 className="maf_h5 my-1">
                <svg className="maf_our_services_icon me-1" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.948 12.0495L18.9487 12.0507L20.8737 15.9007C21.0495 16.2523 21.3513 16.5715 21.676 16.8118C21.9989 17.0508 22.3933 17.2482 22.788 17.3084L26.2684 17.8909C26.2686 17.8909 26.2688 17.891 26.269 17.891C27.3 18.0664 27.7638 18.4934 27.8907 18.886C28.017 19.2771 27.8916 19.891 27.1507 20.6319L27.1497 20.6329L24.4382 23.359C24.438 23.3591 24.4379 23.3593 24.4377 23.3594C24.1373 23.6601 23.9234 24.0773 23.8028 24.4942C23.682 24.9116 23.6391 25.3828 23.7354 25.8067L23.7356 25.8077L24.5085 29.1764L24.5087 29.1771C24.8049 30.4607 24.5486 31.0991 24.2358 31.328C23.9228 31.5571 23.236 31.6083 22.1008 30.9387C22.1006 30.9385 22.1003 30.9384 22.1 30.9382L18.8345 28.9993L18.8318 28.9977C18.4388 28.7677 17.9563 28.6667 17.5 28.6667C17.0437 28.6667 16.5613 28.7677 16.1683 28.9977L16.1679 28.998L12.8866 30.923L12.8855 30.9236C11.7499 31.5937 11.0628 31.5425 10.7497 31.3134C10.4369 31.0846 10.1806 30.4461 10.4768 29.1625L10.477 29.1619L11.2495 25.7946C11.2496 25.7943 11.2497 25.794 11.2498 25.7937C11.347 25.3748 11.303 24.9062 11.1827 24.4905C11.0621 24.0737 10.8492 23.6559 10.5529 23.3501L10.553 23.35L10.5473 23.3444L7.84943 20.6465C7.10931 19.9064 6.98271 19.2885 7.10955 18.8948C7.23642 18.5009 7.69962 18.0741 8.72859 17.906L8.72859 17.906L8.73049 17.9057L12.2159 17.3224L12.2159 17.3224L12.2196 17.3217C12.6067 17.254 12.998 17.0602 13.3224 16.8222C13.6462 16.5846 13.9496 16.2689 14.1264 15.9153L16.0514 12.0653L16.0514 12.0653L16.0527 12.0628C16.534 11.0866 17.0901 10.766 17.5014 10.7649C17.9122 10.7638 18.4672 11.081 18.948 12.0495Z" fill="#004DF9" stroke="#004DF9" />
                  <path d="M8.75 14.2187C8.15208 14.2187 7.65625 13.7229 7.65625 13.125V2.91663C7.65625 2.31871 8.15208 1.82288 8.75 1.82288C9.34792 1.82288 9.84375 2.31871 9.84375 2.91663V13.125C9.84375 13.7229 9.34792 14.2187 8.75 14.2187Z" fill="#004DF9" />
                  <path d="M26.25 14.2187C25.6521 14.2187 25.1562 13.7229 25.1562 13.125V2.91663C25.1562 2.31871 25.6521 1.82288 26.25 1.82288C26.8479 1.82288 27.3438 2.31871 27.3438 2.91663V13.125C27.3438 13.7229 26.8479 14.2187 26.25 14.2187Z" fill="#004DF9" />
                  <path d="M17.5 6.92704C16.9021 6.92704 16.4062 6.43121 16.4062 5.83329V2.91663C16.4062 2.31871 16.9021 1.82288 17.5 1.82288C18.0979 1.82288 18.5938 2.31871 18.5938 2.91663V5.83329C18.5938 6.43121 18.0979 6.92704 17.5 6.92704Z" fill="#004DF9" />
                </svg>

                WEBSITE RANKING</h5>
              <p className="maf_p_s mb-0">Our goal is to help your website achieve higher search engine rankings and increased visibility, leading to more potential customers all over the world and ultimately, increased revenue.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
