import React from 'react'
import image from '../assets/img/Home.png';
import team_work from '../assets/img/team_work.jpeg';

export default function Home() {
  return (
    <>
      <section className="maf_home_slide_1 maf_content d-flex flex-column justify-content-center">
        <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row">
          <div className="col-12 col-md-5 mt-4 mt-md-0 d-flex flex-column justify-content-center order-1 order-md-0">
            <p className="maf_blue_p mb-3">
              HOME
            </p>
            <h1 className="maf_h1 mb-0">SOLOUTION OF EVERYTHING</h1>
            <p className="maf_p mb-0">Creating a better future, one byte at a time. With each line of code, each circuit board, and each network connection, we are bringing the future to life. We are creating a more connected, more efficient, and more sustainable future. One byte at a time, we are building a better world for all.</p>
            <button className="maf_btn">
              Check it Now
              <span className="maf_arrow_icon">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.9299 5.92993L20.9999 11.9999L14.9299 18.0699" stroke="#FEFEFE" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M4 12H20.83" stroke="#FEFEFE" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </span>
            </button>
          </div>
          <div className="col-12 col-md-7 d-flex flex-column justify-content-center order-0 order-md-1">
            <div className="maf_col_img ps-md-4">
              <img src={team_work} className="maf_img" alt="Home"/>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
