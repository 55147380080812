import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import { readTime } from '../App';
import DOMPurify from "dompurify";

export default function BlogDetails() {
    const [blogDetails, setBlogDetails] = useState(null);
    const { id } = useParams();
    useEffect(() => {
        fetch(`http://localhost:3001/api/blogs/getBlog/${id}`)
            .then(response => response.json())
            .then(data => setBlogDetails(data));
    }, [id]);
    return (
        <>
            {blogDetails ? (
                <section className="maf_home_slide_1 maf_content d-flex flex-column justify-content-center">
                    <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row">
                        <div className="col-12 col-md-6 d-flex flex-column justify-content-center order-1 order-md-0">
                            <div className="col d-flex flex-column">
                                <h2 className="card-title maf_h2">{blogDetails.short_title}</h2>
                                <div className="card-text maf_p_s m-0 mb-3" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(blogDetails.desc) }} />;
                                {/* <p className="card-text maf_p_s m-0 mb-3">{blogDetails.desc}</p> */}
                                {/* <p className="card-text maf_p_s m-0">. PageMaker</p>
                                <p className="card-text maf_p_s m-0">. Ncluding</p>
                                <p className="card-text maf_p_s m-0">. Versions</p>
                                <p className="card-text maf_p_s m-0">. Lorem Ipsum</p> */}
                                <div className="col d-flex mt-3">
                                    <span className="col d-flex align-items-center gap_1rm height_fit_con">
                                        <p className="maf_p m-0">{readTime(blogDetails.desc)}</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 d-flex flex-column justify-content-center order-0 order-md-1">
                            <span className="ps-md-4">
                                <img src={require(`../assets/img/${blogDetails.image}`)} className="maf_blog_img" alt={blogDetails.short_title} />

                            </span>
                        </div>
                    </div>
                </section>
            ) : (
                <div></div>
            )}
            <Footer />
        </>
    )
}



