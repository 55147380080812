import React from 'react'
import BlogHome1 from "../assets/img/Blog_1_home.png"
import BlogHome2 from "../assets/img/Blog_2_home.png"
import BlogHome3 from "../assets/img/Blog_3_home.png"
export default function Blogs() {
  return (
    <section className="maf_home_slide_1 d-none maf_content  d-flex flex-column justify-content-center">
      <div className="d-flex flex-column">
        <div className="col-12 d-flex flex-column justify-content-center">
          <p className="maf_blue_p mb-3">
            Blogs
          </p>
          <h1 className="maf_h1 mb-0">CHECK OUT OUR LATETS BLOGS</h1>
        </div>
        <div className="col-12 d-flex flex-column justify-content-center">
          <div className="col-12 maf_blog_cards mt-2">
            <div className="card col-12 maf_card maf_br_xt8p">
              <img src={BlogHome1} className="card-img-top " alt="..."/>
                <div className="card-body d-flex flex-column">
                  <h4 className="card-title maf_h4 m-0">Our top services trending in market this year</h4>
                  <p className="card-text maf_p_xs">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                  <span className="col d-flex  align-items-end">
                    <a href="/" className="maf_a">10min Read</a>
                  </span>
                </div>
            </div>
            <div className="card col-12 maf_card maf_br_xt8p">
              <img src={BlogHome2} className="card-img-top " alt="..."/>
                <div className="card-body d-flex flex-column">
                  <h4 className="card-title maf_h4 m-0">Upcoming services by our company are live now</h4>
                  <p className="card-text maf_p_xs">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                  <span className="col d-flex  align-items-end">
                    <a href="/" className="maf_a">10min Read</a>
                  </span>
                </div>
            </div>
            <div className="card col-12 maf_card maf_br_xt8p">
              <img src={BlogHome3} className="card-img-top " alt="..."/>
                <div className="card-body d-flex flex-column">
                  <h4 className="card-title maf_h4 m-0">Guide to be more successfull course is live now</h4>
                  <p className="card-text maf_p_xs">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                  <span className="col d-flex  align-items-end">
                    <a href="/" className="maf_a">10min Read</a>
                  </span>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}
