import React from 'react'
import Map from "../assets/img/Map.png"
import Footer from "../components/Footer";
export default function ContactUsPage() {
  return (
    <>
      <section className="maf_home_slide_1 maf_content d-flex flex-column justify-content-center">
        <div className="d-flex flex-column align-items-center">
          <div className="col-12 col-md-8 col-lg-8 d-flex flex-column justify-content-center align-items-center">
            <p className="maf_blue_p mb-0 text-center">
              PORTFOLIO
            </p>
            <h1 className="maf_h1 mb-0 text-center">CONTACT US FOR FUTURE UPDATES AND HELP</h1>
            <p className="maf_p mb-0 text-center">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
            <button className="maf_btn">
              Contact us
            </button>
          </div>
        </div>
      </section>
      
      <section className="maf_home_slide_1 d-none maf_content  d-flex flex-column justify-content-center">
        <div className="maf_gap_768 d-flex flex-column flex-sm-column flex-md-row flex-lg-row">
          <div className="col-12 col-md-7 col-lg-7 d-flex flex-column justify-content-center">
            <div className="maf_col_img">
              <img src={Map} className="maf_img" alt="Map" />
            </div>
          </div>
          <div className="col-12 col-md-5 col-lg-5 mt-3 mt-md-0 ps-md-3 d-flex flex-column justify-content-center">
            <p className="maf_blue_p mb-0">
              FOR SUPPORT
            </p>
            <h1 className="maf_h1 mb-0">CONTACT US</h1>
            <p className="maf_p mb-0">
              Get in touch with our friendly and knowledgeable team for any questions or concerns. Whether you need help with a product or just want to share your thoughts and feedback, we're here to listen and provide the support you need. Our goal is to make sure you have a positive experience, and we're dedicated to ensuring your satisfaction. Don't hesitate to reach out to us, we're just a message away and always happy to help.<br />
              Connect with us today and let us know how we can assist you.
            </p>
            <span className="maf_btn d-flex">
              <form>
                <input type="email" className="maf_email maf_p_s" placeholder="Enter you email here" />
              </form>
              <button className="maf_btn mt-0 maf_arrow_icon" type="submit">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.9299 5.92993L20.9999 11.9999L14.9299 18.0699" stroke="#FEFEFE" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M4 12H20.83" stroke="#FEFEFE" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
            </span>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
