import React from 'react'
import About_us from '../assets/img/About_us.png';
export default function AboutUs() {
  return (
    <section className="maf_home_slide_1 d-none maf_content d-flex flex-column justify-content-center">
      <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row">
        <div className="col-12 col-md-7 d-flex flex-column justify-content-center">
          <div className="maf_col_img_parent pe-md-4">
            <img src={About_us} className="maf_img_child" alt="about-us" />
          </div>
        </div>
        <div className="col-12 col-md-5 mt-3 mt-md-0 d-flex flex-column justify-content-center">
          <p className="maf_blue_p mb-3">
            About us
          </p>
          <h1 className="maf_h1 mb-0">WHO ARE WE, WHAT WE DO?</h1>
          <p className="maf_p ">
            We are a software development company, committed to building innovative and cutting-edge technology that helps businesses and individuals achieve their goals. Our team is comprised of experienced developers, designers, and project managers who work together to deliver high-quality software solutions.We specialize in developing custom software for a variety of industries, including healthcare, finance, and e-commerce.
          </p><p className="maf_p mb-0">
            Our services include web and mobile development, cloud computing, and data analytics. We use the latest technologies to create responsive, user-friendly, and secure software that meets our client's unique needs.
             {/* With our expertise, we can help you streamline your operations, improve efficiency, and increase revenue. We are dedicated to delivering excellence in everything we do.. */}
             </p>
          <button className="maf_btn">
            Learn More
            <span className="maf_arrow_icon">
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.9299 5.92993L20.9999 11.9999L14.9299 18.0699" stroke="#FEFEFE" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M4 12H20.83" stroke="#FEFEFE" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </span>
          </button>
        </div>
      </div>
    </section>
  )
}
