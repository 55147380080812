import "./App.css";
import React, { useState, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import Menu from "./components/menu/MainMenu";
import Menu from "./components/menu/MainMenu";
import MaafMenu from "./components/NewMenu";
import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import AboutUsPage from "./pages/AboutUsPage";
import Services from "./components/Services";
import ServicesPage from "./pages/ServicesPage";
import PortfolioPage from "./pages/PortfolioPage";
import ContactUsPage from "./pages/ContactUsPage";
import BlogsPage from "./pages/BlogsPage";
import Testimonials from "./components/Testimonials";
import Portfolio from "./components/Portfolio";
import Blogs from "./components/Blogs";
import Footer from "./components/Footer";
import ArrowIcon from "./assets/img/arrow-icon.svg";
import ContactUs from "./components/ContactUs";
import BlogDetails from "./pages/BlogDetails";
// 31.440912, 73.713124
export default function App() {
  const [menuState, setMenuState] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [endOfTheRoad, setEndOfTheRoad] = useState(false);
  const [canSlide, setCanslide] = useState(true);

  const toggleMenu = () => {
    setMenuState(!menuState);
  }

  const resetSlider = () => {
    if (window.innerWidth > 425) {
      setCurrentSlide(0);
      setEndOfTheRoad(false);
    }
    setMenuState(false);
  }

  const switchSlides = (slide) => {
    const elements = document.getElementsByClassName('maf_home_slide_1')
    if (slide === 'down' && elements[currentSlide + 1] !== undefined) {
      elements[currentSlide].classList.add('d-none');
      elements[currentSlide].classList.remove('slide-up-down', 'slide-down-up');
      elements[currentSlide + 1].classList.remove('d-none');
      elements[currentSlide + 1].classList.add('slide-up-down');
      setCurrentSlide(currentSlide + 1);
      if (elements[currentSlide + 2] === undefined) {
        setEndOfTheRoad(true);
      } else {
        setEndOfTheRoad(false);
      }
    } else if (slide === 'up' && elements[currentSlide - 1] !== undefined) {
      elements[currentSlide].classList.add('d-none');
      elements[currentSlide - 1].classList.remove('d-none');
      elements[currentSlide].classList.remove('slide-up-down', 'slide-down-up');
      elements[currentSlide - 1].classList.add('slide-down-up');
      setCurrentSlide(currentSlide - 1);
      if (elements[currentSlide - 1] === undefined) {
        setEndOfTheRoad(true);
      } else {
        setEndOfTheRoad(false);
      }
    }

  }

  const handleScroll = (e) => {
    if (canSlide && window.innerWidth > 425) {
      if (e.deltaY > 1) {
        switchSlides('down');
      } else if (e.deltaY < -1) {
        switchSlides('up');
      }
      setCanslide(false);
      setTimeout(() => {
        setCanslide(true);
      }, 1500);
    }
  };

  const handleKeyDown = (event) => {
    if (window.innerWidth > 425) {
      if (event.key === 'ArrowDown') {
        switchSlides('down');
      } else if (event.key === 'ArrowUp') {
        switchSlides('up');
      }
    }
  };

  useEffect(() => {
    if (window.innerWidth > 425) {
      document.body.style.overflow = 'hidden';
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        document.body.style.overflow = 'unset';
        window.removeEventListener('keydown', handleKeyDown);
      };
    } else {
      const elements = document.querySelectorAll(".maf_home_slide_1");
      elements.forEach((element) => {
        element.classList.remove("d-none");
      });
    }
  });

  return (
    <>
      <div className="maf_main d-flex flex-column" tabIndex={0} onWheel={handleScroll} onKeyDown={handleKeyDown} style={{ outline: 'unset' }}>
        <BrowserRouter>
          {/* <Menu menuState={menuState} toggleMenu={toggleMenu} currentSlide={currentSlide} handleChange={resetSlider} ></Menu> */}
          <MaafMenu menuState={menuState} toggleMenu={toggleMenu} currentSlide={currentSlide} handleChange={resetSlider}/>
          <Routes>
            <Route exact path="/" element={
              <>
                <Home />
                <AboutUs />
                <Services />
                <Testimonials />
                <Portfolio />
                <Blogs />
                <ContactUs />
                <Footer />
              </>} />
            <Route exact path="/about-us" element={<AboutUsPage />} />
            <Route exact path="/services" element={<ServicesPage />} />
            <Route exact path="/portfolio" element={<PortfolioPage />} />
            <Route exact path="/contact-us" element={<ContactUsPage />} />
            <Route exact path="/blogs" element={<BlogsPage />} />
            <Route exact path="/blogDetails/:id" element={<BlogDetails />} />
          </Routes>
        </BrowserRouter>
        {endOfTheRoad === false && (
          <footer className="maf_footer container-fluid p-0 d-flex justify-content-end">
            <button className="btn p-0 maf_clear_btn" onClick={() => switchSlides('down')}>
              <img src={ArrowIcon} alt="arrow icon" />
            </button>
          </footer>
        )}
      </div>
    </>
  );
}
export function readTime(str) {
  const wordsPerMinute = 200;
  const wordCount = str.trim().split(/\s+/).length;
  const readTimeMinutes = Math.ceil(wordCount / wordsPerMinute);
  return `${readTimeMinutes}min${readTimeMinutes !== 1 ? 's Read' : ' Read'}`;
}