import React from 'react'
import History1 from '../assets/img/History_1.png'
import History2 from '../assets/img/History_2.png'
import Owner from '../assets/img/Owner.jpg'
import SEOExpert from '../assets/img/seo_expert.png'
import Designer from '../assets/img/Designer.jpg'
import MarketingExpert from '../assets/img/Digital_Marketer.png'
import Slider from "react-slick";
import Footer from "../components/Footer";

export default function AboutUsPage() {

    const sliderSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 2500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: true,
        swipeToSlide: true,
        className: 'maf_custom_class',
    };
    return (
        <>
            <section className="maf_content maf_home_slide_1 d-flex flex-column justify-content-center">
                <div className="maf_gap_768 d-flex flex-column align-items-center">
                    <div className="col-12 col-md-8 col-lg-8 d-flex flex-column justify-content-center align-items-center">
                        <p className="maf_blue_p mb-0 text-start text-center">
                            ABOUT US
                        </p>
                        <h1 className="maf_h1 mb-0 text-start text-center">WE ARE MORE THAN JUST TECHNOLOGY</h1>
                        <p className="maf_p mb-0 text-start-md text-center">
                            We are a dynamic team of experts who are passionate about using technology to solve real-world problems. So if you're looking for a partner that is more than just a technology company, look no further - we are here to help you achieve your goals and drive progress in a rapidly changing world.
                        </p>
                        <button className="maf_btn_play">
                            <span className="">
                                <svg width="84" height="84" className="maf_play_icon" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="42" cy="42" r="30" fill="#004DF9" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M49.5764 39.4141C51.5484 40.5741 51.5484 43.4258 49.5764 44.5857L38.5211 51.0889C36.5212 52.2653 34 50.8233 34 48.5031L34 35.4967C34 33.1765 36.5212 31.7345 38.5211 32.9109L49.5764 39.4141Z" fill="white" />
                                    <circle cx="42" cy="42" r="41.5" stroke="white" strokeOpacity="0.24" />
                                </svg>
                            </span>
                        </button>
                    </div>
                </div>
            </section>
            <section className="maf_content maf_home_slide_1 d-flex flex-column justify-content-center">
                <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row">
                    <div className="col-12 col-md-5 mt-4 mt-md-0 d-flex flex-column justify-content-center order-1 order-md-0">
                        <p className="maf_blue_p mb-0">
                            HISTORY
                        </p>
                        <h1 className="maf_h1 mb-0">HOW OUR STUDIO BEGAN</h1>
                        <p className="maf_p mb-0">
                            Our journey as a software development company started with a group of like-minded individuals who shared a passion for technology and a desire to make a difference. In the early days, we were a small team of developers and designers working out of a single room, using our skills and ingenuity to create innovative solutions for local businesses. As our reputation grew, so did our team, and before long, we had become one of the leading software development companies in the region.
                        </p>
                    </div>
                    <div className="col-12 col-md-7 d-flex flex-column justify-content-center order-0 order-md-1">
                        <div className="maf_col_img ps-md-4">
                            <img src={History1} className="maf_img" alt='History1' />
                        </div>
                    </div>
                </div>
            </section>
            <section className="maf_content maf_home_slide_1 d-flex flex-column justify-content-center">
                <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row">
                    <div className="col-12 col-md-7 d-flex flex-column justify-content-center">
                        <div className="maf_col_img pe-md-4">
                            <img src={History2} className="maf_img" alt='History2' />
                        </div>
                    </div>
                    <div className="col-12 col-md-5 mt-3 mt-md-0 d-flex flex-column justify-content-center">
                        <p className="maf_blue_p mb-0">
                            HISTORY
                        </p>
                        <h1 className="maf_h1 mb-0">ABOUT OUR STUDIO</h1>
                        <p className="maf_p mb-0">
                            Our story began with three individuals who had a shared passion for technology and a vision to make a difference. Starting in a small room with limited resources, they put their hearts and souls into developing innovative software solutions. As their reputation grew, so did their team, and soon they had become a leading software development company known for delivering exceptional results.
                            <br />
                            Despite facing many challenges along the way, they never lost sight of their mission, and their hard work, dedication, and commitment to quality paid off. <br />
                            Today, the small team of developers and designers runs a thriving software house, delivering cutting-edge solutions and helping businesses transform with technology.
                        </p>
                    </div>
                </div>
            </section>
            <section className="maf_content maf_home_slide_1 d-flex flex-column justify-content-center">
                <div className="d-flex flex-column align-items-center">
                    <div className="col-12 col-md-8 d-flex flex-column justify-content-center align-items-center">
                        <p className="maf_blue_p mb-0 text-center">
                            PARTNERSHIP
                        </p>
                        <h1 className="maf_h1 mb-0 text-center">INEGRITY, TRANSFORMATION, PARTNERSHIP</h1>
                        <p className="maf_p mb-0 text-center">
                            At our software house, INTEGRITY is at the forefront of everything we do. We believe in building strong, trustworthy partnerships with our clients, based on mutual respect and a shared commitment to excellence.
                            <br />
                            Our passion for technology and drive for TRANSFORMATION drives us to deliver innovative software solutions that help businesses succeed.
                            <br />
                            By working in close PARTNERSHIP with our clients, we help turn their ideas into reality, delivering results that make a real impact in the world. Trust us to bring integrity, expertise, and a passion for transformation to your next project.
                        </p>
                    </div>
                </div>
            </section>
            <section className="maf_content maf_home_slide_1 d-flex flex-column justify-content-center">
                <div className="d-flex flex-column flex-sm-column flex-md-column flex-lg-column">
                    <div className="col-12 d-flex flex-column justify-content-center">
                        <p className="maf_blue_p mb-0">
                            TEAM
                        </p>
                        <h1 className="maf_h1 mb-0">OUR HARD <br /> WORKING TEAM</h1>
                    </div>
                    <div className="col-12 d-flex mt-3 mt-md-0 flex-column justify-content-center">
                        <Slider {...sliderSettings}>
                            <div className="item mx-2">
                                <a href="/" className="maf_a">
                                    <div className="card col-12 maf_card_img maf_br_xt12p">
                                        <img src={Owner} className="card-img-top maf_team_card_img" alt="C.E.O" />
                                        <div className="card-body">
                                            <h6 className="card-title maf_h6">Mian Asif Faheem</h6>
                                            <p className="card-text maf_p_xs">C.E.O / Full Stack Developer</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="item mx-2">
                                <a href="/" className="maf_a">
                                    <div className="card col-12 maf_card_img maf_br_xt12p">
                                        <img src={SEOExpert} className="card-img-top maf_team_card_img" alt="SEO Expert" />
                                        <div className="card-body">
                                            <h6 className="card-title maf_h6">Hassan Mehmood</h6>
                                            <p className="card-text maf_p_xs">SEO Expert</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="item mx-2">
                                <a href="/" className="maf_a">
                                    <div className="card col-12 maf_card_img maf_br_xt12p">
                                        <img src={Designer} className="card-img-top maf_team_card_img" alt="UX/UI Designer" />
                                        <div className="card-body">
                                            <h6 className="card-title maf_h6">Umair Khan</h6>
                                            <p className="card-text maf_p_xs">UX/UI Designer</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="item mx-2">
                                <a href="/" className="maf_a">
                                    <div className="card col-12 maf_card_img maf_br_xt12p">
                                        <img src={MarketingExpert} className="card-img-top maf_team_card_img" alt="..." />
                                        <div className="card-body">
                                            <h6 className="card-title maf_h6">Changaiz Khan</h6>
                                            <p className="card-text maf_p_xs">Marketing Expert</p>
                                        </div>
                                    </div>
                                </a>
                            </div>

                        </Slider>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
